import React, { useCallback, useState, useLayoutEffect, useEffect } from 'react'
import { Zettel } from '../../types/graphql'

import { useStoreActions } from '../../types/hooks'
import { fetchData } from '../../util/serverCommunication'
import zettelBySearchterm from '../../graphql/queries/zettelBySearchterm'

type Props = {
    initialSearchTerm?: string
    index: number
}

const SearchPanel = (props: Props) => {
    const { initialSearchTerm, index } = props
    const closePanel = useStoreActions((actions) => actions.model.closePanel)
    const convertToZettelPanel = useStoreActions((actions) => actions.model.convertToZettelPanel)
    const openZettelPanel = useStoreActions((actions) => actions.model.openZettelPanel)
    const [searchTerm, setSearchTerm] = useState('')
    const [searchInBody, setSearchInBody] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    // const [errors, setErrors] = useState('') // TODO: handle errors and show them

    // this is for opening a search from the side panel
    useLayoutEffect(() => {
        if (initialSearchTerm) {
            setSearchTerm(initialSearchTerm)
            setInputValue(initialSearchTerm)
        }
    }, [])

    useEffect(() => {
        fetchData(zettelBySearchterm, { searchterm: searchTerm, searchInBody }).then((data) => {
            setData(data.data.zettelBySearchterm)
            setLoading(false)
        })
    }, [searchTerm, searchInBody])

    const onCloseClick = useCallback(() => {
        closePanel(index)
    }, [closePanel, index])

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value)
        },
        [setSearchTerm],
    )
    const onOpenClick = useCallback(
        (id: string) => {
            openZettelPanel({ id, index })
        },
        [openZettelPanel],
    )

    const onConvertClick = useCallback(
        (id: string) => {
            convertToZettelPanel({ index, id })
        },
        [openZettelPanel],
    )

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) {
                setSearchTerm(inputValue)
            }
        },
        [inputValue],
    )

    const onSearchClicked = useCallback(() => {
        setSearchTerm(inputValue)
    }, [inputValue])

    return (
        <div className="panel">
            <div
                className="close"
                onClick={() => {
                    onCloseClick()
                }}
            >
                ×
            </div>
            <h3 className="title">
                {searchTerm === '' ? 'All Zettels' : `Search for "${searchTerm}"`}
                {/* <span
                    onClick={() => {
                        onStarToggle(zettel.id)
                    }}
                    className={starred ? 'starred' : 'star'}
                >
                    ★
                </span> */}
            </h3>
            <div className="flexrow spacebetween">
                <input type="text" value={inputValue} onChange={onChange} onKeyDown={onKeyDown} disabled={loading} />
                <label>
                    Also search in Zettel body
                    <input
                        type="checkbox"
                        checked={searchInBody}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchInBody(e.target.checked)
                        }}
                    />
                </label>
                <button disabled={loading} onClick={onSearchClicked}>
                    search
                </button>
            </div>

            <div className="resultList">
                {data &&
                    data.map((z: Zettel) => {
                        return (
                            <div className="resultEntry" key={z.id}>
                                <span onClick={() => onConvertClick(z.id)}>{z.title}</span>
                                <span onClick={() => onOpenClick(z.id)} className="icon">
                                    {' '}
                                    ⇒
                                </span>
                            </div>
                        )
                    })}
                {!loading && searchTerm !== '' && data && data.length === 0 && (
                    <div className="errorMsg">No search results found</div>
                )}
            </div>
        </div>
    )
}

export default SearchPanel
