import React, { useCallback } from 'react'
import { Zettel, ArchivedVersion } from '../../types/graphql'
import { format, toDate, formatDistance } from 'date-fns'

import { useStoreActions } from '../../types/hooks'

type Props = {
    index: number
    zettel: Zettel
}

const HistoryList = (props: Props) => {
    const { zettel, index } = props
    const openArchivedZettelPanel = useStoreActions((actions) => actions.model.openArchivedZettelPanel)
    // const [data, setData] = useState([])

    // useEffect(() => {
    //     fetchData(zettelByLink, { id }).then((data) => {
    //         setData(data.data.zettelByLink)
    //     })
    // }, [id])

    const onOpenClick = useCallback(
        (version: number) => {
            openArchivedZettelPanel({ id: zettel.id, index, version })
        },
        [openArchivedZettelPanel],
    )

    const history = [...zettel.history]
    history.reverse()

    return (
        <div>
            <h4 className="title">History</h4>
            {history.map((av: ArchivedVersion) => {
                const t = toDate(Number(av.createdAt) * 1000)
                return (
                    <div className="historyEntry" key={av.createdAt} onClick={() => onOpenClick(av.version)}>
                        <span>version {av.version}</span>
                        <span>
                            {format(t, 'yyyy.MM.dd HH:mm')} - ({formatDistance(t, new Date())} ago)
                        </span>
                        <span>{av.author.name}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default HistoryList
