import gql from 'graphql-tag'

export default gql`
    query zettelVersionByID($id: String!, $version: Int!) {
        zettelVersionByID(id: $id, version: $version) {
            id
            title
            content
            author {
                userID
                name
            }
            createdAt
            version
        }
    }
`
