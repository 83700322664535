import React, { useCallback, useState, useEffect } from 'react'
import { Zettel, Link } from '../../types/graphql'

import { useStoreActions } from '../../types/hooks'
import { fetchData } from '../../util/serverCommunication'
import zettelByLink from '../../graphql/queries/zettelByLink'

type Props = {
    id: string
    index: number
}

const LinksList = (props: Props) => {
    const { id, index } = props
    const openZettelPanel = useStoreActions((actions) => actions.model.openZettelPanel)
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData(zettelByLink, { id }).then((data) => {
            setData(data.data.zettelByLink)
        })
    }, [id])

    const onOpenClick = useCallback(
        (id: string) => {
            openZettelPanel({ id, index })
        },
        [openZettelPanel],
    )

    return (
        <div>
            <h4 className="title">{data && data.length > 0 ? 'Links to here' : 'no links found'}</h4>
            {data &&
                data.map((z: Zettel) => {
                    return (
                        <div className="linkResultEntry" key={z.id}>
                            <h3 onClick={() => onOpenClick(z.id)}>{z.title} ⇒</h3>
                            {z.links.map((l: Link) => {
                                return (
                                    <div className="linkSnippet">
                                        {l.qualification && <b>({l.qualification.toLocaleUpperCase()}) - </b>}
                                        ...{z.content?.substring(l.contextFrom, l.contextTo)}...
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
        </div>
    )
}

export default LinksList
