import { action, Action, Thunk, thunk } from 'easy-peasy'
import { User } from '../types/graphql'
import { fetchData } from '../util/serverCommunication'
import addZettel from '../graphql/mutations/addZettel'
import saveZettel from '../graphql/mutations/saveZettel'
import addUser from '../graphql/mutations/addUser'

export type PanelConfig = {
    type: 'zettel' | 'archivedZettel' | 'search' | 'help'
    zettelID?: string
    zettelVersion?: number
    searchTerm?: string
}

export interface Model {
    // Zettels that are open in my UI right now
    panels: PanelConfig[]
    // Is the new dialog open right now?
    newDialogOpen: boolean
    // Is the add new link open right now?
    addLinkDialogOpen: boolean
    // Is the user management open?
    accountManagementOpen: boolean
    // The user currently logged in
    user?: User
    bookmarks: string[]

    // Actions
    toggleBookmark: Action<Model, string>
    addZettel: Thunk<Model, string>
    addUser: Thunk<Model, { id?: string; name: string; email: string; admin: boolean }>
    saveZettel: Thunk<Model, { id: string; title?: string; content?: string }>
    setNewDialog: Action<Model, boolean>
    setAddLinkDialog: Action<Model, boolean>
    setAccountManagementDialog: Action<Model, boolean>
    closePanel: Action<Model, number>
    openZettelPanel: Action<Model, { index: number; id: string }>
    openArchivedZettelPanel: Action<Model, { index: number; id: string; version: number }>
    openSearchPanel: Action<Model, { index?: number; searchTerm: string }>
    openHelpPanel: Action<Model>
    convertToZettelPanel: Action<Model, { index: number; id: string }>
    setUser: Action<Model, User | undefined>
    resetState: Action<Model>
}

const model: Model = {
    // Data
    bookmarks: [],
    panels: [],
    newDialogOpen: false,
    addLinkDialogOpen: false,
    accountManagementOpen: false,
    // zettels: [],
    user: undefined,

    // Close a panel
    closePanel: action((state, index) => {
        state.panels.splice(index, 1)
    }),
    // Open a panel showing a zettel
    openZettelPanel: action((state, payload) => {
        const index = state.panels.findIndex(
            (panel: PanelConfig) => panel.type === 'zettel' && panel.zettelID === payload.id,
        )
        if (index === -1) {
            state.panels.splice(payload.index + 1, 0, { type: 'zettel', zettelID: payload.id })
        }
    }),
    // Open a panel showing an archived zettel
    openArchivedZettelPanel: action((state, payload) => {
        const index = state.panels.findIndex(
            (panel: PanelConfig) =>
                panel.type === 'archivedZettel' &&
                panel.zettelID === payload.id &&
                panel.zettelVersion === payload.version,
        )
        if (index === -1) {
            state.panels.splice(payload.index + 1, 0, {
                type: 'archivedZettel',
                zettelID: payload.id,
                zettelVersion: payload.version,
            })
        }
    }),
    // Open a panel showing a zettel
    openHelpPanel: action((state) => {
        state.panels.unshift({ type: 'help' })
    }),
    // Open a panel showing a search
    openSearchPanel: action((state, payload) => {
        // open a new panel with the searchterm
        if (!payload.index) {
            state.panels.unshift({ type: 'search', searchTerm: payload.searchTerm })
        } else {
            state.panels.splice(payload.index + 1, 0, { type: 'search', searchTerm: payload.searchTerm })
        }
    }),
    // Make a search panels into a zettel
    convertToZettelPanel: action((state, payload: { index: number; id: string }) => {
        const index = state.panels.findIndex(
            (panel: PanelConfig) => panel.type === 'zettel' && panel.zettelID === payload.id,
        )
        if (index === -1) {
            state.panels[payload.index].type = 'zettel'
            state.panels[payload.index].zettelID = payload.id
            state.panels[payload.index].searchTerm = ''
        } else {
            const move = state.panels.splice(index, 1) // get the existing one
            state.panels.splice(payload.index, 1, move[0])
        }
    }),
    // set whether the new dialog is open or not (if nothing else is open)
    setNewDialog: action((state, open) => {
        if (!state.addLinkDialogOpen && !state.accountManagementOpen) state.newDialogOpen = open
    }),
    // set whether the add link dialog is open or not (if nothing else is open)
    setAddLinkDialog: action((state, open) => {
        if (!state.newDialogOpen && !state.accountManagementOpen) state.addLinkDialogOpen = open
    }),
    // set whether the add link dialog is open or not (if nothing else is open)
    setAccountManagementDialog: action((state, open) => {
        if (!state.newDialogOpen && !state.addLinkDialogOpen) state.accountManagementOpen = open
    }),
    toggleBookmark: action((state, payload) => {
        const index = state.bookmarks.findIndex((bookmarksID: string) => bookmarksID === payload)
        if (index > -1) state.bookmarks.splice(index, 1)
        else state.bookmarks.push(payload)
    }),
    setUser: action((state, user) => {
        state.user = user
        state.newDialogOpen = false
        state.addLinkDialogOpen = false
    }),

    addUser: thunk(async (actions, payload) => {
        const data = await fetchData(addUser, {
            id: payload.id,
            name: payload.name,
            email: payload.email,
            admin: payload.admin,
        })
        if (data.data && data.data.addUser) {
            actions.setNewDialog(false)
            // actions.openZettelPanel({ id: data.data.addZettel.id, index: 0 })
        } else if (data.errors) return data.errors
    }),
    addZettel: thunk(async (actions, title) => {
        const data = await fetchData(addZettel, { title })
        if (data.data && data.data.addZettel) {
            actions.setNewDialog(false)
            actions.openZettelPanel({ id: data.data.addZettel.id, index: 0 })
        } else if (data.errors) return data.errors
    }),
    saveZettel: thunk(async (_, payload) => {
        const data = await fetchData(saveZettel, { id: payload.id, content: payload.content })
        if (data.data && data.data.saveZettel) {
            return data.data.saveZettel
        }
        return undefined
    }),
    resetState: action((state) => {
        state.user = undefined
        state.panels = []
        state.newDialogOpen = false
        state.addLinkDialogOpen = false
        state.accountManagementOpen = false
        state.bookmarks = []
    }),
}

export default model
