import React, { useCallback } from 'react'

import { useStoreActions } from '../../types/hooks'
import renderMarkdown from '../../util/renderMarkdown'

type Props = {
    index: number
}

const helptext = `## How to use deepthought
This is a [Zettelkasten](https://en.wikipedia.org/wiki/Zettelkasten) implementation. The idea is that deepthought collects thoughts, notes, snippets but allows you to create deep relationships between the zettels. This is done through links. 

The format used for writing the notes is [Markdown](https://en.wikipedia.org/wiki/Markdown)

You add links to pages through the regular markdown syntax, but the URL you point to is structured a bit differently. We allow you to point to a different zettel, to a specific anchor in a zettel and you can always add a qualification to the link. An example would be to say the content of a zettel "supports" another, or "contradicts".

### Link syntax
To make a link use this syntax:
\`[Link text](link destination)\`
where the link text is anything you want. You do not need to enter the destination by hand (altough you can) but use the add link function in the editor toolbar, which will open a dialog that helps you create a link. If you do want to type it by hand, the format of the link is:
\`zettel ID + "#" + anchor + ":" + qualification\`. 
It is safer to use the dialog, which can also be opened by pressing f4.

### Attributes
You can add attributes to a zettel by placing anywhere in its content an assignment in the shape of:
\`name::value\`
Where the name cannot contain spaces but should be made up of letters, numbers, hypens or underscores. 

The value is everything until the end of the line. You can use this to assign multiple attributes to a zettel, each on a separate line. The attributes can be used in the search. 
`

const HelpPanel = (props: Props) => {
    const closePanel = useStoreActions((actions) => actions.model.closePanel)

    const onCloseClick = useCallback(() => {
        closePanel(props.index)
    }, [closePanel, props.index])

    return (
        <div className="panel">
            <div
                className="close"
                onClick={() => {
                    onCloseClick()
                }}
            >
                ×
            </div>
            <h3 className="title">Help</h3>
            <div
                className="panelContainer"
                dangerouslySetInnerHTML={{
                    __html: renderMarkdown(helptext),
                }}
            />
        </div>
    )
}

export default HelpPanel
