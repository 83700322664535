import EasyMDE from 'easymde'
import renderMarkdown from '../../util/renderMarkdown'

const mdeOptions: EasyMDE.Options = {
    inputStyle: 'contenteditable',
    spellChecker: false,
    nativeSpellcheck: true,
    uploadImage: true,

    // @ts-ignore
    maxHeight: '70vh',
    toolbar: [
        {
            name: 'bold',
            action: EasyMDE.toggleBold,
            className: 'fa fa-bold',
            title: 'Bold (Ctrl-B)',
        },
        {
            name: 'italic',
            action: EasyMDE.toggleItalic,
            className: 'fa fa-italic',
            title: 'Italic (Ctrl-I)',
        },
        {
            name: 'heading',
            action: EasyMDE.toggleHeading2,
            className: 'fa fa-header fa-heading',
            title: 'Heading (Ctrl-H)',
        },
        '|',
        {
            name: 'quote',
            action: EasyMDE.toggleBlockquote,
            className: 'fa fa-quote-left',
            title: "Quote (Ctrl-')",
        },
        {
            name: 'unordered-list',
            action: EasyMDE.toggleUnorderedList,
            className: 'fa fa-list-ul',
            title: 'Generic List (Ctrl-L)',
        },
        {
            name: 'ordered-list',
            action: EasyMDE.toggleOrderedList,
            className: 'fa fa-list-ol',
            title: 'Numbered list (Ctrl-Alt-L)',
        },
        '|',
        {
            name: 'image',
            action: EasyMDE.drawImage,
            className: 'fa fa-image',
            title: 'Insert Image (Ctrl-Alt-I)',
        },
        '|',
        {
            name: 'preview',
            action: EasyMDE.togglePreview,
            className: 'fa fa-eye',
            noDisable: true,
            title: 'Preview (Ctrl-P)',
        },
        {
            name: 'fullscreen',
            action: EasyMDE.toggleFullScreen,
            className: 'fa fa-arrows-alt',
            noDisable: true,
            noMobile: true,
            title: 'Toggle Fullscreen (F11)',
        },
        '|',
        {
            name: 'addLink',
            action: () => {},
            className: 'fa fa-link',
            title: 'Add Link',
        },
    ],
    previewRender: renderMarkdown,
}

export default mdeOptions
