import React, { useCallback, useState, useEffect } from 'react'
import { Zettel } from '../../types/graphql'

import { useStoreActions } from '../../types/hooks'
import { fetchData } from '../../util/serverCommunication'
import renderMarkdown from '../../util/renderMarkdown'
import zettelVersionByID from '../../graphql/queries/zettelVersionByID'
import { formatDistance, toDate } from 'date-fns'

type Props = {
    id: string
    version: number
    index: number
}

enum ViewMode {
    VIEW = 'VIEW',
    SOURCE = 'SOURCE',
}

type NavibuttonProps = {
    label: string
    vm: ViewMode
}

const ArchivedZettelPanel = (props: Props) => {
    const { id, index, version } = props
    const closePanel = useStoreActions((actions) => actions.model.closePanel)
    const [loading, setLoading] = useState(true)
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.VIEW)
    const [zettel, setZettel] = useState<Zettel>()
    const openZettelPanel = useStoreActions((actions) => actions.model.openZettelPanel)
    const openSearchPanel = useStoreActions((actions) => actions.model.openSearchPanel)

    // const [errors, setErrors] = useState('') // TODO: handle errors and show them

    // const toggleBookmarks = useStoreActions((actions) => actions.model.toggleBookmark)
    // const bookmarks = useStoreState((state) => state.model.bookmarks)
    // const starred = bookmarks.findIndex((b: string) => b === zettel.id) > -1

    useEffect(() => {
        fetchData(zettelVersionByID, { id, version }).then((data) => {
            setZettel(data.data.zettelVersionByID)
            setLoading(false)
        })
    }, [id])

    const onCloseClick = useCallback(
        (i: number) => {
            closePanel(i)
        },
        [closePanel],
    )

    const active = useCallback(
        (vm: ViewMode) => {
            return vm === viewMode ? 'active' : 'inactive'
        },
        [viewMode],
    )

    const onSwitchViewMode = useCallback(
        (vm: ViewMode) => {
            setViewMode(vm)
        },
        [setViewMode, viewMode],
    )

    ;((window || global) as any).markdownLinkClicked = (id: string) => {
        if (id.startsWith('search?')) {
            // TODO: better parsing and passing into search
            openSearchPanel({ searchTerm: id.substr(11), index: props.index })
        } else {
            openZettelPanel({ id, index: props.index })
        }
    }

    const Navibutton = (props: NavibuttonProps) => {
        return (
            <button
                className={active(props.vm)}
                onClick={() => {
                    onSwitchViewMode(props.vm)
                }}
            >
                {props.label}
            </button>
        )
    }

    // const onStarToggle = useCallback(
    //     (id: string) => {
    //         toggleBookmarks(id)
    //     },
    //     [toggleBookmarks],
    // )

    if (!zettel) return <div className={'panel loading'}></div>

    const t = toDate(Number(zettel.createdAt) * 1000)

    return (
        <div className={loading ? 'panel loading' : 'panel'}>
            <div
                className="close"
                onClick={() => {
                    onCloseClick(index)
                }}
            >
                ×
            </div>
            {zettel && (
                <>
                    <div className="flexrow">
                        <h3 className="title">
                            {zettel.title + ' '} (v{zettel.version})
                        </h3>
                    </div>
                    <div className="zettelMeta">
                        edited by {zettel.author.name} {formatDistance(t, new Date())} ago
                    </div>
                    <div className="flexrow  mb">
                        <Navibutton label="view" vm={ViewMode.VIEW} />{' '}
                        <Navibutton label="source" vm={ViewMode.SOURCE} />
                    </div>
                    <div className="panelContainer">
                        {viewMode === ViewMode.VIEW && zettel.content && (
                            <div
                                className="renderedZettel"
                                dangerouslySetInnerHTML={{
                                    __html: renderMarkdown(zettel.content),
                                }}
                            />
                        )}
                        {viewMode === ViewMode.SOURCE && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: zettel.content
                                        ? zettel.content.replace(/(?:\r\n|\r|\n)/g, '<br>')
                                        : '(empty)',
                                }}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default ArchivedZettelPanel
