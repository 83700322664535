import { DocumentNode } from 'graphql'

const uri = process.env.REACT_APP_SERVER_URL

export const fetchData = async (query: DocumentNode, variables: {}) => {
    if (!query.loc) return

    const token = localStorage.getItem('auth')
    if (!token) return { error: 'not logged in' }

    let response
    try {
        // TODO: it may be neccessary to do const d = new Date() + d.getTime()
        response = await fetch(uri + '/query', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({ query: query.loc.source.body, variables }),
        })
    } catch (e) {
        console.log(e)
    }
    // only proceed once promise is resolved
    if (!response) return undefined
    let data = await response.json()
    // only proceed once second promise is resolved
    return data
}

export const login = async (username: string, password: string) => {
    // await response of fetch call
    let response
    try {
        response = await fetch(uri + '/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ user: username, password }),
        })
    } catch (e) {
        console.log(e)
    }
    // only proceed once promise is resolved
    if (!response) return undefined
    let data = await response.json()
    // only proceed once second promise is resolved
    return data
}

export const logout = async () => {
    let response
    const token = localStorage.getItem('auth')
    if (!token) return { error: 'not logged in' }
    try {
        response = await fetch(uri + '/logout', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: token,
            },
        })
    } catch (e) {
        console.log(e)
    }
    localStorage.removeItem('auth')

    // only proceed once promise is resolved
    if (!response) return undefined
    let data = await response.json()
    // only proceed once second promise is resolved
    return data
}

// export const requestNewPassword =

export const saveNewPassword = async (password: string, token: string) => {
    let response
    try {
        response = await fetch(uri + '/savepassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ password, token }),
        })
    } catch (e) {
        console.log(e)
    }

    // only proceed once promise is resolved
    if (!response) return undefined
    let data = await response.json()
    // only proceed once second promise is resolved
    return data
}

export const checkSession = async () => {
    // await response of fetch call
    let response
    const token = localStorage.getItem('auth')
    if (!token) return { error: 'not logged in' }
    try {
        response = await fetch(uri + '/checksession', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: token,
            },
        })
    } catch (e) {
        console.log(e)
    }
    // only proceed once promise is resolved
    if (!response) return undefined
    let data = await response.json()
    // only proceed once second promise is resolved
    return data
    // return Math.random() > 5 ? { id: '12345', userName: 'linkwill' } : undefined
}
