import React, { useState, useRef, useEffect, useCallback } from 'react'
import SimpleMDE from 'react-simplemde-editor'
import mdeOptions from './mdeOptions'
import 'easymde/dist/easymde.min.css'
import { useStoreActions } from '../../types/hooks'
import debounce from 'lodash.debounce'
import { Zettel } from '../../types/graphql'
// import renderMarkdown from '../../util/renderMarkdown'

type Props = {
    content: string
    id: string
    index: number
    updateZettel: (zettel: Zettel) => void
}

function Editor(props: Props) {
    const [networkState, setNetworkState] = useState<'saving' | 'ready' | 'changed'>('ready')
    const stateRef = useRef<'saving' | 'ready' | 'changed'>('ready')
    const editorRef = useRef<SimpleMDE | null>(null)
    const codemirror = useRef<CodeMirror.Editor | null>(null)
    const saveZettel = useStoreActions((actions) => actions.model.saveZettel)
    const setAddLinkDialog = useStoreActions((actions) => actions.model.setAddLinkDialog)

    const onChange = useCallback((debounced: any) => {
        stateRef.current = 'changed'
        if (networkState === 'ready') setNetworkState('changed')
        debounced()
    }, [])

    ;((window || global) as any).insertLinktext = (linkText: string) => {
        insertLink(linkText)
    }

    const insertLink = (linkText: string) => {
        const cm = codemirror.current
        if (!cm) return
        let startPoint = cm.getCursor('start')
        let endPoint = cm.getCursor('end')
        cm.replaceRange(linkText, startPoint, endPoint)
        cm.focus()
        setAddLinkDialog(false)
    }

    const o: EasyMDE.Options = mdeOptions
    if (o && o.toolbar) {
        //@ts-ignore
        const addL = o.toolbar.find((entry: any) => {
            return entry instanceof Object && entry.name === 'addLink'
        })
        if (addL) {
            addL.action = (easymde: EasyMDE) => {
                setAddLinkDialog(true)
                codemirror.current = easymde.codemirror
            }
        }
    }

    useEffect(() => {
        const el = editorRef.current
        if (!el || !el.simpleMde) {
            return
        }
        const debounced = debounce(() => {
            if (el == null) {
                return
            }
            ;(async () => {
                stateRef.current = 'saving'
                const newContent = el.simpleMde?.value()
                const newZettel = await saveZettel({ id: props.id, content: newContent })
                props.updateZettel(newZettel)
                stateRef.current = 'ready'
                setNetworkState('ready')
                // TODO: catch errors, does not return anything
            })()
        }, 1200)

        const handler = () => {
            onChange(debounced)
        }
        el.simpleMde.codemirror.on('change', handler)
        return () => {
            if (!el || !el.simpleMde) {
                return
            }
            el.simpleMde.codemirror.off('change', handler)
        }
    }, [editorRef])

    return (
        <div className={'editor ' + networkState}>
            <SimpleMDE ref={editorRef} value={props.content} options={mdeOptions} />
        </div>
    )
}

export default Editor
