import React, { useState, useCallback } from 'react'
import SideBar from '../sidebar/SideBar'
import ZettelPanel from '../zettelPanel/ZettelPanel'
import ArchivedZettelPanel from '../archivedZettelPanel/ArchivedZettelPanel'
import SearchPanel from '../searchPanel/SearchPanel'
import HelpPanel from '../helpPanel/HelpPanel'
import { useStoreState } from 'easy-peasy'
import { PanelConfig } from '../../store/model'
import CreateNewDialog from '../createNewDialog/CreateNewDialog'
import AddLinkDialog from '../addLinkDialog/AddLinkDialog'
import AccountManagementDialog from '../accountManagementDialog/AccountManagementDialog'

function MainView() {
    const panels = useStoreState((state) => state.model.panels)
    const user = useStoreState((state) => state.model.user)
    const newDialogOpen = useStoreState((state) => state.model.newDialogOpen)
    const addLinkDialogOpen = useStoreState((state) => state.model.addLinkDialogOpen)
    const accountManagementOpen = useStoreState((state) => state.model.accountManagementOpen)

    return (
        <div className="container">
            <SideBar />
            <div className="panels">
                {panels.map((pc: PanelConfig, i: number) => {
                    if (pc.type === 'zettel') {
                        if (pc.zettelID) return <ZettelPanel id={pc.zettelID} index={i} key={pc.zettelID} />
                    } else if (pc.type === 'archivedZettel') {
                        if (pc.zettelID && pc.zettelVersion)
                            return (
                                <ArchivedZettelPanel
                                    id={pc.zettelID}
                                    version={pc.zettelVersion}
                                    index={i}
                                    key={pc.zettelID}
                                />
                            )
                    } else if (pc.type === 'search') {
                        return <SearchPanel initialSearchTerm={pc.searchTerm} key={'s' + pc.searchTerm} index={i} />
                    } else if (pc.type === 'help') {
                        return <HelpPanel index={i} />
                    }
                    return null
                })}
            </div>
            {newDialogOpen && <CreateNewDialog />}
            {addLinkDialogOpen && <AddLinkDialog />}
            {accountManagementOpen && <AccountManagementDialog />}
        </div>
    )
}

export default MainView
