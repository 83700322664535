import gql from 'graphql-tag'

export default gql`
    mutation saveUser($id: ID, $name: String!, $email: String!, $admin: Boolean!) {
        saveUser(id: $id, name: $name, email: $email, admin: $admin) {
            id
            name
            email
            role
        }
    }
`
