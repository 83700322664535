import React, { useState, useEffect, useCallback } from 'react'
import { ZettelSnippet } from '../../types/graphql'

import { fetchData } from '../../util/serverCommunication'
import zettelSnippets from '../../graphql/queries/zettelSnippets'
import { useStoreActions } from '../../types/hooks'

type Props = {
    title: string
    id: string
    index: number
}

const UnlinkedReferenceList = (props: Props) => {
    const { title, id, index } = props
    const openZettelPanel = useStoreActions((actions) => actions.model.openZettelPanel)
    const [data, setData] = useState([])

    useEffect(() => {
        fetchData(zettelSnippets, { str: title }).then((data) => {
            setData(data.data.zettelSnippets)
        })
    }, [title])

    const onOpenClick = useCallback(
        (sid: string) => {
            openZettelPanel({ id: sid, index })
        },
        [openZettelPanel],
    )

    const dataFiltered = data.filter((zs: ZettelSnippet) => zs.id != id)

    return (
        <div>
            <h4 className="title">
                {dataFiltered && dataFiltered.length > 0 ? 'Unliked references' : 'No unlinked references found'}
            </h4>
            {dataFiltered &&
                dataFiltered.map((zs: ZettelSnippet) => {
                    return (
                        <div className="linkResultEntry flexrow spacebetween" key={zs.id}>
                            <span>
                                <a
                                    onClick={(e) => {
                                        console.log(e)
                                        e.preventDefault()
                                        onOpenClick(zs.id)
                                    }}
                                    href="#"
                                >
                                    {zs.title}
                                </a>
                                : {zs.context}
                            </span>
                            <button>link</button>
                        </div>
                    )
                })}
        </div>
    )
}

export default UnlinkedReferenceList
