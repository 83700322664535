import gql from 'graphql-tag'

export default gql`
    mutation saveZettel($id: String!, $title: String, $content: String) {
        saveZettel(id: $id, title: $title, content: $content) {
            id
            title
            content
            author {
                userID
                name
            }
            history {
                version
                author {
                    userID
                    name
                }
                createdAt
            }
            updatedAt
            createdAt
        }
    }
`
