import gql from 'graphql-tag'

export default gql`
    query zettelSnippets($str: String!) {
        zettelSnippets(searchterm: $str) {
            id
            context
            position
            title
        }
    }
`
