import React, { useState, useCallback } from 'react'

import { useStoreActions } from '../../types/hooks'
import { login } from '../../util/serverCommunication'
import { useHistory } from 'react-router-dom'

function LoginDialog() {
    const setUser = useStoreActions((state) => state.model.setUser)
    const [username, setUsername] = useState('')
    const [pw, setPW] = useState('')
    const [error, setError] = useState('')
    let history = useHistory()

    const onChangeUsername = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value)
        setError('')
    }, [])

    const onChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPW(e.target.value)
        setError('')
    }, [])

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) onLoginClick()
    }

    const onLoginClick = useCallback(async () => {
        const response = await login(username, pw)
        if (!response) {
            setError('could not connect to server')
        } else if (!response.error) {
            setUser(response.user)
            localStorage.setItem('auth', response.token)
            history.push('/kasten')
        } else setError('please try logging in with credentials that work')
    }, [username, pw, setUser])

    return (
        <div className="overlay">
            <div className="background"></div>
            <img src="dtbg.jpg" className="bgimg" />
            <div className="dialog">
                <h3>Sign in</h3>
                {error && <div className="errorMsg">{error}</div>}
                <div className="flexrow spacing spacebetween">
                    Username: <input type="text" autoFocus value={username} onChange={onChangeUsername} />
                </div>
                <div className="flexrow spacing mt spacebetween">
                    Password: <input type="password" value={pw} onChange={onChangePassword} onKeyDown={onKeyDown} />
                </div>
                <div className="flexrow mt flexend">
                    <button onClick={onLoginClick}>sign in</button>
                </div>
            </div>
        </div>
    )
}

export default LoginDialog
