import React, { useCallback, useState, useEffect } from 'react'
import Editor from '../editor/Editor'
import { Zettel } from '../../types/graphql'

import { useStoreActions } from '../../types/hooks'
import { fetchData } from '../../util/serverCommunication'
import zettelByID from '../../graphql/queries/zettelByID'
import LinksList from '../linksList/LinksList'
import UnlinkedReferenceList from '../unlinkedReferencesList/UnlinkedReferencesList'
import renderMarkdown from '../../util/renderMarkdown'
import HistoryList from '../historyList/HistoryList'
import { formatDistance, toDate } from 'date-fns'

type Props = {
    id: string
    index: number
}

enum ViewMode {
    VIEW = 'VIEW',
    EDIT = 'EDIT',
    LINKS = 'LINKS',
    FIND_LINKS = 'FIND_LINKS',
    COMMENTS = 'COMMENTS',
    HISTORY = 'HISTORY',
}

type NavibuttonProps = {
    label: string
    vm: ViewMode
}

const ZettelPanel = (props: Props) => {
    const { id, index } = props
    const closePanel = useStoreActions((actions) => actions.model.closePanel)
    const [loading, setLoading] = useState(true)
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.VIEW)
    const [zettel, setZettel] = useState<Zettel>()
    const openZettelPanel = useStoreActions((actions) => actions.model.openZettelPanel)
    const openSearchPanel = useStoreActions((actions) => actions.model.openSearchPanel)

    // const [errors, setErrors] = useState('') // TODO: handle errors and show them

    // const toggleBookmarks = useStoreActions((actions) => actions.model.toggleBookmark)
    // const bookmarks = useStoreState((state) => state.model.bookmarks)
    // const starred = bookmarks.findIndex((b: string) => b === zettel.id) > -1

    useEffect(() => {
        fetchData(zettelByID, { id }).then((data) => {
            setZettel(data.data.zettelByID)
            setLoading(false)
        })
    }, [id])

    const onCloseClick = useCallback(
        (i: number) => {
            closePanel(i)
        },
        [closePanel],
    )

    const active = useCallback(
        (vm: ViewMode) => {
            return vm === viewMode ? 'active' : 'inactive'
        },
        [viewMode],
    )

    const updateZettel = useCallback(
        (zl: Zettel) => {
            setZettel(zl)
        },
        [viewMode],
    )

    const onSwitchViewMode = useCallback(
        (vm: ViewMode) => {
            setViewMode(vm)
        },
        [setViewMode, viewMode],
    )

    ;((window || global) as any).markdownLinkClicked = (id: string) => {
        if (id.startsWith('search?')) {
            // TODO: better parsing and passing into search
            openSearchPanel({ searchTerm: id.substr(11), index: props.index })
        } else {
            openZettelPanel({ id, index: props.index })
        }
    }

    const Navibutton = (props: NavibuttonProps) => {
        return (
            <button
                className={active(props.vm)}
                onClick={() => {
                    onSwitchViewMode(props.vm)
                }}
            >
                {props.label}
            </button>
        )
    }

    // const onStarToggle = useCallback(
    //     (id: string) => {
    //         toggleBookmarks(id)
    //     },
    //     [toggleBookmarks],
    // )

    if (!zettel) return <div className={'panel loading'}></div>

    const t = toDate(Number(zettel.updatedAt) * 1000)

    return (
        <div className={loading ? 'panel loading' : 'panel'}>
            <div
                className="close"
                onClick={() => {
                    onCloseClick(index)
                }}
            >
                ×
            </div>
            {zettel && (
                <>
                    <div className="flexrow">
                        <h3 className="title">
                            {zettel.title}
                            <span className={false ? 'starred' : 'star'}>★</span>
                        </h3>
                    </div>
                    <div className="zettelMeta">
                        edited by {zettel.author.name} {formatDistance(t, new Date())} ago
                    </div>
                    <div className="flexrow spacebetween mb">
                        <Navibutton label="view" vm={ViewMode.VIEW} />
                        <Navibutton label="edit" vm={ViewMode.EDIT} />
                        <Navibutton label="links to here" vm={ViewMode.LINKS} />
                        <Navibutton label="find links" vm={ViewMode.FIND_LINKS} />
                        <Navibutton label="comments" vm={ViewMode.COMMENTS} />
                        <Navibutton label="history" vm={ViewMode.HISTORY} />
                    </div>
                    <div className="panelContainer">
                        {viewMode === ViewMode.FIND_LINKS && (
                            <UnlinkedReferenceList title={zettel.title} id={id} index={index} />
                        )}
                        {viewMode === ViewMode.LINKS && <LinksList id={id} index={index} />}
                        {viewMode === ViewMode.VIEW && zettel.content && (
                            <div
                                className="renderedZettel"
                                dangerouslySetInnerHTML={{
                                    __html: renderMarkdown(zettel.content),
                                }}
                            />
                        )}
                        {viewMode === ViewMode.EDIT && (
                            <Editor
                                content={zettel.content ? zettel.content : ''}
                                id={id}
                                index={index}
                                updateZettel={updateZettel}
                            />
                        )}
                        {viewMode === ViewMode.COMMENTS && <div>Not yet implemented, sorry.</div>}
                        {viewMode === ViewMode.HISTORY && <HistoryList zettel={zettel} index={index} />}
                    </div>
                </>
            )}
        </div>
    )
}

export default ZettelPanel
