import React, { useState, useCallback } from 'react'

import { saveNewPassword } from '../../util/serverCommunication'
import { useParams, useHistory } from 'react-router-dom'

type RouteParams = { token: string }

function scorePassword(pass: string) {
    var score: number = 0
    if (!pass) return score

    type LetterScores = Record<string, number>

    // award every unique letter until 5 repetitions
    var letters: LetterScores = {}
    for (var i = 0; i < pass.length; i++) {
        const l = pass[i]
        letters[l] = (letters[l] || 0) + 1
        score += 5.0 / letters[l]
    }

    type PatternScores = Record<string, boolean>

    // bonus points for mixing it up
    var variations: PatternScores = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass),
    }

    var variationCount = 0
    for (var check in variations) {
        variationCount += variations[check] == true ? 1 : 0
    }
    score += (variationCount - 1) * 10

    return score
}

function pwStrengthAsString(pass: string) {
    var score = scorePassword(pass)
    if (score > 80) return 'strong'
    else if (score > 60) return 'getting better'
    else if (score >= 30) return 'not there yet'

    return '-'
}

function NewPasswordDialog() {
    const [pw1, setPW1] = useState('')
    const [pw2, setPW2] = useState('')
    const [sent, setSent] = useState(false)
    const [error, setError] = useState('')
    const { token } = useParams<RouteParams>()
    let history = useHistory()

    const onChangePW1 = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPW1(e.target.value)
        setError('')
    }, [])

    const onChangePW2 = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPW2(e.target.value)
        setError('')
    }, [])

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) onSavePWClick()
    }

    const onSavePWClick = useCallback(async () => {
        const response = await saveNewPassword(pw1, token)
        if (!response) {
            setError('could not connect to server')
        } else if (!response.error) {
            setSent(true)
        } else setError('could not set new password (' + response.error + ')')
    }, [pw1, pw2, setSent])

    const gotoLogin = () => {
        history.push('/login')
    }

    const pwsame = pw1 && pw1 === pw2
    const pwlength = pw1 && pw1.length >= 8
    const lowerc = /[a-z]/.test(pw1)
    const upperc = /[A-Z]/.test(pw1)
    const digits = /\d/.test(pw1)
    const special = /\W/.test(pw1)
    const quality = scorePassword(pw1)
    const qualityStr = pwStrengthAsString(pw1)
    const pwinc = pw1 && pw1.toLowerCase().indexOf('password') > -1

    const saveable = pwsame && pwlength && lowerc && upperc && digits && quality > 80 && !pwinc

    return (
        <div className="overlay">
            <div className="background"></div>
            <img src="dtbg.jpg" className="bgimg" />
            {!sent && (
                <div className="dialog">
                    <h3>Set new password</h3>
                    {error && <div className="errorMsg">{error}</div>}
                    <div className="flexrow spacing spacebetween">
                        New password: <input type="password" autoFocus value={pw1} onChange={onChangePW1} />
                    </div>
                    <div className="flexrow spacing mt spacebetween">
                        Repeat password:{' '}
                        <input type="password" value={pw2} onChange={onChangePW2} onKeyDown={onKeyDown} />
                    </div>
                    <div className="flexrow flexstart pwhelp">
                        {pwsame ? '✓' : ' ⃝'} Passwords match <br />
                        {pwlength ? '✓' : ' ⃝'} At least 8 characters long <br />
                        {lowerc ? '✓' : ' ⃝'} Contains lower case characters <br />
                        {upperc ? '✓' : ' ⃝'} Contains upper case characters <br />
                        {digits ? '✓' : ' ⃝'} Contains a number <br />
                        {special ? '✓' : ' ⃝'} Bonus: contains special chars <br />
                        {pwinc && (
                            <>
                                ⃝ Very funny. Remove "password" from password.
                                <br />
                            </>
                        )}
                        Quality: {qualityStr} <br />
                    </div>
                    <div className="flexrow flexend mt">
                        <button onClick={onSavePWClick} disabled={!saveable}>
                            save
                        </button>
                    </div>
                </div>
            )}
            {sent && (
                <div className="dialog">
                    <h3>New password saved</h3>
                    <div className="flexrow center">
                        <button onClick={gotoLogin}>Go to login</button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default NewPasswordDialog
