import React, { useState, useCallback, useEffect } from 'react'
import { Zettel } from '../../types/graphql'

import { useStoreActions } from '../../types/hooks'
import { fetchData } from '../../util/serverCommunication'
import zettelBySearchterm from '../../graphql/queries/zettelBySearchterm'

function AddLinkDialog() {
    const setAddLinkDialog = useStoreActions((actions) => actions.model.setAddLinkDialog)
    // const addZettel = useStoreActions((actions) => actions.model.addZettel)
    const [searchTerm, setSearchTerm] = useState('')
    const [linkText, setLinkText] = useState('')
    const [anchor, setAnchor] = useState('')
    const [qualification, setQualification] = useState('')
    const [zettel, setZettel] = useState<Zettel | undefined>(undefined)
    const [searchData, setSearchData] = useState<Zettel[]>([])
    const [selectedResult, setSelectedResult] = useState(0)

    const onDismissClick = useCallback(() => {
        setAddLinkDialog(false)
    }, [setAddLinkDialog])

    const onCreateClick = () => {
        let linkstr = '[' + linkText + ']('
        if (zettel) linkstr += zettel.id
        if (anchor) linkstr += '#' + anchor
        if (qualification) linkstr += ':' + qualification
        linkstr += ')'
        ;((window || global) as any).insertLinktext(linkstr)
    }

    useEffect(() => {
        if (searchTerm.length > 2) {
            fetchData(zettelBySearchterm, { searchterm: searchTerm }).then((data) => {
                if (data.data) setSearchData(data.data.zettelBySearchterm)
            })
        } else {
            setSearchData([])
        }
    }, [searchTerm])

    const onAutocompleteKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 38 && searchData && searchData.length > 0) {
            setSelectedResult((selectedResult + searchData.length - 1) % searchData.length)
        } else if (e.keyCode === 40 && searchData && searchData.length > 0) {
            setSelectedResult((selectedResult + 1) % searchData.length)
        } else if (e.keyCode === 13 && searchData && searchData.length > 0) {
            const zl = searchData[selectedResult]
            setZettel(zl)
            setLinkText(zl.title)
        } else if (e.keyCode === 27) {
            onDismissClick()
        }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 27) {
            onDismissClick()
        }
    }

    return (
        <div className="overlay">
            <div
                className="background"
                onClick={() => {
                    onDismissClick()
                }}
            ></div>
            <div className="dialog">
                <h3>Add link</h3>
                {!zettel && (
                    <>
                        <div className="flexrow spacing">
                            Autocomplete:{' '}
                            <input
                                type="text"
                                autoFocus
                                value={searchTerm}
                                onKeyDown={onAutocompleteKeyDown}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value)
                                }}
                            />
                        </div>
                        {searchData &&
                            searchData.length > 0 &&
                            searchData.map((zl: Zettel, i: number) => {
                                return (
                                    <div
                                        key={zl.id}
                                        className={i === selectedResult ? 'addLinkEntry selected' : 'addLinkEntry'}
                                        onClick={() => {
                                            setZettel(zl)
                                            setLinkText(zl.title)
                                        }}
                                    >
                                        {zl.title}
                                    </div>
                                )
                            })}
                    </>
                )}
                {zettel && (
                    <>
                        {zettel.anchors && zettel.anchors.length > 0 && (
                            <div className="flexrow spacebetween">
                                Anchor:{' '}
                                <select
                                    value={anchor}
                                    onChange={(e) => {
                                        setAnchor(e.target.value)
                                    }}
                                >
                                    <option value="">none</option>
                                    {zettel.anchors.map((an: string, i: number) => {
                                        return (
                                            <option value={an} key={an + i}>
                                                {an}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        )}
                        <div className="flexrow spacebetween mt">
                            Linktext:{' '}
                            <input
                                type="text"
                                value={linkText}
                                onKeyDown={onKeyDown}
                                autoFocus
                                onChange={(e) => {
                                    setLinkText(e.target.value)
                                }}
                            />
                        </div>
                        <div className="flexrow spacing spacebetween mt">
                            Qualification:{' '}
                            <input
                                type="text"
                                value={qualification}
                                onKeyDown={onKeyDown}
                                onChange={(e) => {
                                    setQualification(e.target.value)
                                }}
                            />
                        </div>
                        <div className="flexrow flexend mt">
                            <button onClick={onCreateClick}>create</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

/*
todo: 
- add qualification
- anchors is a dropdown and initially disabled because no zettel
- linktext gets autocompleted with note title
- target page loads search results debounced, after at least 3 chars
*/

export default AddLinkDialog
