import gql from 'graphql-tag'

export default gql`
    query zettelBySearchterm($searchterm: String!, $searchInBody: Boolean) {
        zettelBySearchterm(searchterm: $searchterm, searchInBody: $searchInBody) {
            id
            title
            anchors
            createdAt
        }
    }
`
