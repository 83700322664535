import React, { useCallback, useState } from 'react'

import { useStoreActions, useStoreState } from '../../types/hooks'
import { PanelConfig } from '../../store/model'
import { logout } from '../../util/serverCommunication'

function SideBar() {
    const bookmarks = useStoreState((state) => state.model.bookmarks)
    const panels = useStoreState((state) => state.model.panels)
    const user = useStoreState((state) => state.model.user)
    const setUser = useStoreActions((actions) => actions.model.setUser)
    const resetState = useStoreActions((actions) => actions.model.resetState)
    const openZettelPanel = useStoreActions((actions) => actions.model.openZettelPanel)
    const openSearchPanel = useStoreActions((actions) => actions.model.openSearchPanel)
    const openHelpPanel = useStoreActions((actions) => actions.model.openHelpPanel)
    const setNewDialogOpen = useStoreActions((actions) => actions.model.setNewDialog)
    const setAccountManagementDialogOpen = useStoreActions((actions) => actions.model.setAccountManagementDialog)
    const [searchTerm, setSearchTerm] = useState('')

    const onZettelClick = useCallback(
        (id: string) => {
            openZettelPanel({ id, index: 0 })
        },
        [openZettelPanel],
    )

    const onHelpClick = useCallback(() => {
        openHelpPanel()
    }, [openHelpPanel])

    const onNewZettelClick = useCallback(() => {
        setNewDialogOpen(true)
    }, [setNewDialogOpen])

    const onSearch = useCallback(() => {
        openSearchPanel({ searchTerm })
        setSearchTerm('')
    }, [openSearchPanel, searchTerm])

    const onLogout = useCallback(() => {
        logout()
        resetState()
    }, [openSearchPanel, searchTerm, setUser])

    const onManageAccount = useCallback(() => {
        setAccountManagementDialogOpen(true)
    }, [setAccountManagementDialogOpen])

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value)
        },
        [setSearchTerm],
    )

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.keyCode === 13) {
                onSearch()
            }
        },
        [openSearchPanel, searchTerm],
    )

    return (
        <div className="sidebar">
            <div className="flexcol spacebetween p1">
                {user ? user.name : ''}
                <button className="mt" onClick={onLogout}>
                    sign out
                </button>
                {user && user.role === 'admin' && (
                    <button className="mt" onClick={onManageAccount}>
                        manage account
                    </button>
                )}
            </div>
            <hr />
            <div className="flexcol spacebetween p1">
                <input
                    type="text"
                    placeholder="search term"
                    value={searchTerm}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
                <button className="mt" onClick={onSearch}>
                    {searchTerm === '' ? 'show all zettels' : 'search'}
                </button>
                <button className="mt" onClick={() => onNewZettelClick()}>
                    new zettel
                </button>
                <button className="mt" onClick={() => onHelpClick()}>
                    Help
                </button>
            </div>
            <hr />
            <div className="flexcol spacebetween mt p1">
                <h3>Bookmarks</h3>
                {bookmarks.map((b: string) => {
                    // const zettel = zettels.find((z: Zettel) => z.id === b)
                    const zettel = { title: 'bla' }
                    const isOpen = panels.find(
                        (p: PanelConfig) => p.type === 'zettel' && p.zettelID && p.zettelID === b,
                    )
                    return (
                        <div key={b} className="entry" onClick={() => onZettelClick(b)}>
                            {zettel?.title}
                            {isOpen ? '*' : ''}
                        </div>
                    )
                })}
            </div>
            <div className="version">
                v {process.env.REACT_APP_VERSION} build {process.env.REACT_APP_BUILD_NUMBER}
            </div>
        </div>
    )
}

export default SideBar
