import React, { useEffect, useCallback, useState } from 'react'
import './App.css'

import { useStoreState, useStoreActions } from './types/hooks'
import LoginDialog from './components/loginDialog/LoginDialog'
import { checkSession } from './util/serverCommunication'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import MainView from './components/mainView/MainView'
import NewPasswordDialog from './components/newPasswordDialog/NewPasswordDialog'

const PrivateRoute = ({ component, isAuthenticated, render, ...rest }: any) => {
    const routeComponent = (props: any) =>
        isAuthenticated ? React.createElement(component, props) : <Redirect to={{ pathname: '/login' }} />
    if (component) return <Route {...rest} component={routeComponent} />
    else return <Route {...rest} render={render} />
}

function App() {
    const user = useStoreState((state) => state.model.user)
    const newDialogOpen = useStoreState((state) => state.model.newDialogOpen)
    const setNewDialog = useStoreActions((state) => state.model.setNewDialog)
    const setAddLinkDialog = useStoreActions((state) => state.model.setAddLinkDialog)
    const setUser = useStoreActions((state) => state.model.setUser)
    const [checkingSession, setCheckingSession] = useState(true)

    const keyFunction = useCallback(
        (event) => {
            // console.log(event.keyCode)
            if (event.keyCode === 113) {
                // f2
                setNewDialog(true)
            } else if (event.keyCode === 115) {
                // f4
                setAddLinkDialog(true)
            } else if (event.keyCode === 27) {
                if (newDialogOpen) setNewDialog(false)
                if (newDialogOpen) setNewDialog(false)
            }
        },
        [newDialogOpen, setNewDialog],
    )

    useEffect(() => {
        document.addEventListener('keydown', keyFunction, false)

        return () => {
            document.removeEventListener('keydown', keyFunction, false)
        }
    }, [])

    useEffect(() => {
        ;(async () => {
            const response = await checkSession()
            if (!response.error && response.email) {
                setUser(response)
            }
            setCheckingSession(false)
        })()
    }, [])

    const authenticated = !!user

    if (checkingSession && !user) {
        return <span>checking session</span>
    }

    console.log('reendering router', checkingSession, user)

    return (
        <HashRouter>
            <Switch>
                <Route exact={true} path="/login" component={LoginDialog} />
                <PrivateRoute exact={true} path="/kasten" component={MainView} isAuthenticated={authenticated} />
                <Route exact={true} path="/:token/setPassword" component={NewPasswordDialog} />
                {/*<PrivateRoute exact={true} path="/machineType/:id" component={QuestionnaireList} />
                <PrivateRoute
                    exact={true}
                    path="/machineType/:id/questionnaire/:qid/languages"
                    component={LanguageList}
                />
                <PrivateRoute exact={true} path="/machineType/:id/questionnaire/:qid" component={QuestionnaireDetail} /> */}
                <PrivateRoute
                    path="/"
                    isAuthenticated={authenticated}
                    render={() => {
                        return <Redirect to={{ pathname: '/kasten' }} />
                    }}
                />
            </Switch>
        </HashRouter>
    )
}

export default App
