import marked from 'marked'

const renderMarkdown = (text: string): string => {
    const markedOptions: marked.MarkedOptions = {}
    markedOptions.breaks = true
    marked.setOptions(markedOptions)
    const html = marked(text)
    const walk = (el: HTMLElement): void => {
        if (el instanceof HTMLAnchorElement) {
            const href = el.getAttribute('href')
            if (href && href.length > 0 && href[0] !== '/' && !href.startsWith('http')) {
                let id: string = '',
                    anchor: string = '',
                    qualifier: string = ''
                let part: number = 0,
                    partStart: number = 0
                for (let i = 0; i < href.length; i++) {
                    const c = href[i]
                    if (part === 0 && c === '#') {
                        part++
                        id = href.substring(partStart, i)
                        partStart = i + 1
                    } else if (part === 0 && c === ':') {
                        part += 2
                        id = href.substring(partStart, i)
                        partStart = i + 1
                        break
                    } else if (part === 1 && c === ':') {
                        part++
                        anchor = href.substring(partStart, i)
                        partStart = i + 1
                        break
                    }
                }
                if (part === 0) {
                    id = href.substring(partStart)
                } else if (part === 1) {
                    anchor = href.substring(partStart)
                } else if (part === 2) {
                    qualifier = href.substring(partStart)
                }

                el.href =
                    "javascript:markdownLinkClicked('" +
                    escape(id) +
                    "', '" +
                    escape(anchor) +
                    "', '" +
                    escape(qualifier) +
                    "')"
            } else if (href && href.length > 0 && (href.startsWith('http://') || href.startsWith('https://'))) {
                el.target = '_blank'
            }
        }
        for (let ch: HTMLElement = el.firstElementChild as HTMLElement; ch; ch = ch.nextElementSibling as HTMLElement) {
            walk(ch)
        }
    }
    const div: HTMLDivElement = document.createElement('div')
    div.innerHTML = html
    walk(div)
    return div.innerHTML
}

export default renderMarkdown
