import gql from 'graphql-tag'

export default gql`
    mutation createZettel($title: String!, $content: String) {
        addZettel(title: $title, content: $content) {
            id
            createdAt
            version
            title
            content
        }
    }
`
