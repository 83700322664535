import React, { useCallback, useEffect, useState } from 'react'

import { useStoreActions } from '../../types/hooks'
import { fetchData } from '../../util/serverCommunication'
import usersQuery from '../../graphql/queries/users'
import { User } from '../../types/graphql'

function AccountManagementDialog() {
    const setAccountManagementDialog = useStoreActions((state) => state.model.setAccountManagementDialog)
    const addUser = useStoreActions((actions) => actions.model.addUser)
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [admin, setAdmin] = useState(false)
    const [error, setError] = useState('')

    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState<User[]>([])

    useEffect(() => {
        if (loading) {
            fetchData(usersQuery, {}).then((data) => {
                setUsers(data.data.users)
                setLoading(false)
            })
        }
    }, [loading])

    const onNameChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value)
            setError('')
        },
        [setName, setError],
    )

    const onEmailChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
            setError('')
        },
        [setEmail, setError],
    )

    const onAdminChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setAdmin(e.target.checked)
            setError('')
        },
        [setAdmin, setError],
    )

    const onCreateClick = useCallback(async () => {
        addUser({ name, email, admin }).then((errors: any) => {
            if (errors) setError(errors[0].message)
            else {
                setLoading(true)
                setName('')
                setEmail('')
                setAdmin(false)
            }
        })
    }, [name, email, admin, addUser])

    const onDismissClick = useCallback(() => {
        setAccountManagementDialog(false)
    }, [setAccountManagementDialog])

    return (
        <div className="overlay">
            <div className="background"></div>
            <div className="dialog">
                <h3>
                    Account management
                    <span
                        className="close"
                        onClick={() => {
                            onDismissClick()
                        }}
                    >
                        ×
                    </span>
                </h3>
                {users.map((u: User) => {
                    return (
                        <div className="flexrow spacing spacebetween mb">
                            <span>{u.name}</span>
                            <span>{u.email}</span>
                            <span>{u.role}</span>
                        </div>
                    )
                })}
                {/* {error && <div className="errorMsg">{error}</div>} */}
                <h3 className="mt">Create new user</h3>
                <div className="flexrow spacing spacebetween mt">
                    <span>
                        Name: <input type="text" autoFocus value={name} onChange={onNameChange} />
                    </span>
                    <span>
                        Email: <input type="text" value={email} onChange={onEmailChange} />
                    </span>
                    <span>
                        Admin: <input type="checkbox" checked={admin} onChange={onAdminChange} />
                    </span>
                </div>
                <div className="flexrow mt flexend">
                    <button onClick={onCreateClick}>create</button>
                </div>
            </div>
        </div>
    )
}

export default AccountManagementDialog
