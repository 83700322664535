import gql from 'graphql-tag'

export default gql`
    query zettelByLink($id: String!) {
        zettelByLink(targetZettelID: $id) {
            id
            title
            content
            links {
                description
                qualification
                contextFrom
                contextTo
            }
        }
    }
`
