import React, { useState, useCallback } from 'react'

import { useStoreActions } from '../../types/hooks'

function CreateNewDialog() {
    const setNewDialogOpen = useStoreActions((actions) => actions.model.setNewDialog)
    const addZettel = useStoreActions((actions) => actions.model.addZettel)
    const [text, setText] = useState('')
    const [error, setError] = useState('')

    const dismissClick = useCallback(() => {
        setNewDialogOpen(false)
    }, [setNewDialogOpen])

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setText(e.target.value)
            setError('')
        },
        [setText],
    )

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) onCreateClick()
        if (e.keyCode === 27) dismissClick()
    }

    const onCreateClick = useCallback(async () => {
        addZettel(text).then((errors: any) => {
            if (errors) setError(errors[0].message)
        })
    }, [text, addZettel])

    return (
        <div className="overlay">
            <div
                className="background"
                onClick={() => {
                    dismissClick()
                }}
            ></div>
            <div className="dialog">
                <h3>Create new Zettel</h3>
                {error && <div className="errorMsg">{error}</div>}
                <div className="flexrow spacing">
                    Title: <input type="text" autoFocus value={text} onChange={onChange} onKeyDown={onKeyDown} />
                    <button onClick={onCreateClick}>create</button>
                </div>
            </div>
        </div>
    )
}

export default CreateNewDialog
